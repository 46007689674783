import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/material";

const CustomMenu = ({ anchorEl, handleClose, options, handleNav }) => {
  const handle = () => {
    handleClose();
    handleNav && handleNav();
  };

  return (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: "0.5px 1px 2px #e6e6e6",
          overflow: "visible",
          filter: "drop-shadow(none)",
          borderRadius: "10px",
        },
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {options?.map((option, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            handle();
            option.onClick && option.onClick();
          }}
        >
          {option.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenu;
