export const userArray = [
  {
    Id: 1,
    Name: "Ade Wole",
    reviews: "5.00",
    Date: "Jan 12, 2022",
    Status: "Successful",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Lagos, Nigeria",
  },
  {
    Id: 2,
    Name: "Ada Ada",
    reviews: "5.00",
    Date: "Jan 12, 2022",
    Status: "Pending",
    ApprovementStatus: "Pending",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Lagos, Nigeria",
  },
  {
    Id: 3,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Physician",
    location: "Kano, Nigeria",
  },
  {
    Id: 4,
    Name: "Ola Mide",
    reviews: "5.00",
    Date: "Jan 19, 2022",
    Status: "Pending",
    ApprovementStatus: "Pending",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Physician",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 5,
    Name: "Ola Mide",
    reviews: "5.00",
    Date: "Jan 19, 2022",
    Status: "Rejected",
    ApprovementStatus: "Pending",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Physician",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 6,
    Name: "Ade Wole",
    reviews: "5.00",
    Date: "Jan 12, 2022",
    Status: "Successful",
    ApprovementStatus: "Pending",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Physician",
    location: "Abia, Nigeria",
  },
  {
    Id: 7,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    ApprovementStatus: "Pending",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 8,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 9,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Kwara, Nigeria",
  },
  {
    Id: 10,
    Name: "Kolalolaaa Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 11,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Successful",
    email: "person1@gmail.com",
    ApprovementStatus: "Pending",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Kano, Nigeria",
  },
  {
    Id: 12,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Complaint",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 13,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Completed",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Kwara, Nigeria",
  },
  {
    Id: 14,
    Name: "Kolalolaaa Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Ongoing",
    ApprovementStatus: "Verified",
    email: "person1@gmail.com",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Bayelsa, Nigeria",
  },
  {
    Id: 15,
    Name: "Kola Wole",
    reviews: "4.00",
    Date: "Feb 12, 2032",
    Status: "Completed",
    email: "person1@gmail.com",
    ApprovementStatus: "Pending",
    dateApplied: "jan 19, 2023",
    phoneNumber: "0902345667788",
    title: "Pharmacist",
    location: "Kano, Nigeria",
  },
];

export const pendingUsers = [
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
  {
    name: "Slye Sylvester",
    speciality: "Pharmacist",
    amount: "50,000",
    status: "Pending",
  },
];
