import React, { useState } from "react";
import "./JobPageBack.css";
import { jobsArray } from "../../../components/findJobs/Jobs";
import JobItem from "../../../components/jobItem/JobItem";
import { useNavigate } from "react-router-dom";

const JobPageBack = () => {
  const [status, setStatus] = useState("APPROVED");

  const navigate = useNavigate()
  return (
    <div className="job-activity-con">
      <div className="job-activity-board">
        <div className="jobs-tabs">
          <span
            onClick={() => setStatus("APPROVED")}
            className={status === "APPROVED" ? "active" : ""}
          >
            All
          </span>
          <span
            onClick={() => setStatus("PENDING")}
            className={status === "PENDING" ? "active" : ""}
          >
            Open
          </span>
          <span
            onClick={() => setStatus("REJECTED")}
            className={status === "REJECTED" ? "active" : ""}
          >
            Closed
          </span>
        </div>

        <div className="shortlist-right-content">
          <div className="job-list-container">
            {jobsArray.map((job) => {
              return (
                <div
                  onClick={() => navigate(`/joblistings/${job.Id}`)}
                  className="shortlist-jobItem"
                >
                  <JobItem
                    title={job.jobTitle}
                    jobDesc={job.des}
                    rate={job.rate}
                    jobLoc={job.location}
                    jobImg={job.oranizationIMG}
                    jobOrg={job.organization}
                    jobOrgloc={job.organizationLocation}
                    jobId={job.Id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPageBack;
