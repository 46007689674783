import { useEffect, useState } from "react";
import { useActions } from "./useActions";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getAdminUsers } from "../endpoints/auth";

const key = "user-admin-values";

const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const actions = useActions();
  const [keyValues, setKeyValues] = useState(
    getLocalStorageWithExpiration(key)
  );

  const getUserDetails = async (values) => {
    const { token, acc_email } = values;
    setLoading(true)

    try {
      const { data, status } = await getAdminUsers(token);
      if (status === 201 || status === 200 || data?.users) {
        const filterAdminUser = data?.users.filter(
          (user) => user.email === acc_email
        );
        if (filterAdminUser) {
          actions.setUser(...filterAdminUser);
          setLoading(false)
        }
      }
    } catch (error) {
      setLoading(false)
      actions.setUser({});
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get admin users", errorProps);
      }
    }
  };

  useEffect(() => {
    if(keyValues) getUserDetails(keyValues);
  }, [keyValues]);

  return { loading };
};

export default useAuth;
