import React from "react";
import "./ApplicantsContainer.css";
import { FiSearch } from "react-icons/fi";

const ApplicantsContainer = ({
  applicantsTitle,
  show,
  search,
  handleChange,
}) => {
  return (
    <div className="applicants-wrapper">
      <div className="applicants-head">
        <h4>{applicantsTitle}</h4>
        {show && (
          <div className="applicants-filter-box">
            <div className="search-box">
              <FiSearch color="#bbc2cf" />
              <input
                value={search}
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Search applicants name"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplicantsContainer;
