import { successProps } from "../utils";
import { useActions } from "./useActions";
import { toast } from "react-toastify";

const useLogout = () => {
  const actions = useActions();

  const key = "user-admin-values";

  const logOut = () => {
    localStorage.removeItem(key);
    actions.setUser({});
    toast.success("Successfully Logged out", successProps);
  };

  return logOut;
};

export default useLogout;
