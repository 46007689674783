import React, { useState } from "react";
import "./SingleApplicantPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { SlGraduation } from "react-icons/sl";
import DashboardModal from "../../../components/dashboardModal/DashboardModal";
import {
  ApprovalModalContent,
  RejectionModalContent,
  DocumentModalContent,
} from "../../../components/applicantModalContent/ApplicantModalContent";
import cv from "../../../assets/cv.png";
import useSingleUserDetails from "../../../hooks/useSingleUserDetails";
import Skeleton from "@mui/material/Skeleton";
import { config } from "../../../config";

const SingleApplicantPage = () => {
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const navigate = useNavigate();

  const params = useParams();

  const { userDetail } = useSingleUserDetails({
    id: params.id,
    acc_type: params.acc_type,
    navigate,
  });

  const profilePix = `${config.getPublicImages}/${userDetail?.profile_pix}`;

  return (
    <>
      <div className="singleApplicantPageContainer">
        <div className="single-applicant-page-content">
          <div className="page-content-top">
            {userDetail === null ? (
              <Skeleton variant="circular" width={100} height={100} />
            ) : userDetail?.profile_pix === "" ? (
              <div className="top-image">
                <img
                  src={
                    "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
                  }
                  alt="profile"
                />
              </div>
            ) : (
              <div className="top-image">
                <img src={profilePix} alt="profile" />
              </div>
            )}
            <div className="top-name">
              {userDetail === null ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={100}
                />
              ) : (
                <h3>
                  {userDetail?.name.charAt(0).toUpperCase() +
                    userDetail?.name.slice(1)}
                </h3>
              )}
              {userDetail === null ? (
                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={60} />
              ) : (
                <span
                  className={
                    userDetail?.status === "2"
                      ? "status verified"
                      : userDetail?.status === "1"
                      ? "status pending"
                      : null
                  }
                >
                  {userDetail?.status === "1"
                    ? "pending"
                    : userDetail?.status === "2"
                    ? "verified"
                    : ""}
                </span>
              )}
            </div>

            {userDetail === null ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={500} />
            ) : (
              <div className="top-email">
                <p>{userDetail?.email}</p>
                <div className="dot">
                  <p>.</p>
                </div>
                <p>{userDetail?.phone}</p>
                {userDetail?.division !== null && (
                  <div className="dot">
                    <p>.</p>
                  </div>
                )}
                {userDetail?.division !== null && (
                  <p>{userDetail?.division?.name}</p>
                )}
                {userDetail?.address !== "" && (
                  <div className="dot">
                    <p>.</p>
                  </div>
                )}
                {userDetail?.address !== "" && <p>{userDetail?.address}</p>}
              </div>
            )}

            <div className="top-buttons">
              <button
                disabled={userDetail === null}
                className={`btn ${
                  userDetail === null ? "disable-btn" : "block"
                }`}
              >
                Block
              </button>
              <button
                disabled={userDetail === null}
                className={`btn ${
                  userDetail === null ? "disable-btn" : "reject"
                }`}
                onClick={() => {
                  setOpenRejectionModal(true);
                }}
              >
                Reject
              </button>
              <button
                disabled={userDetail === null}
                className={`btn ${
                  userDetail === null ? "disable-btn" : "approve"
                }`}
                onClick={() => {
                  setOpenApprovalModal(true);
                }}
              >
                Approve
              </button>
            </div>
          </div>
          <div className="page-content-bottom">
            <div className="bottom-head">
              <p className="active-tab">Credentials</p>
              <p>Revenue</p>
            </div>
            {userDetail === null ? (
              <div className="bottom-boxes">
                <Skeleton variant="rounded" width={300} height={150} />
                <Skeleton variant="rounded" width={300} height={150} />
              </div>
            ) : (
              <div className="bottom-boxes">
                <div className="box-content">
                  <SlGraduation className="grad-icon" />
                  <h3 className="title">Document Title</h3>
                  <p className="small-title">
                    a subtext to support the document title
                  </p>
                  <div className="btn-box">
                    <button
                      className="btn"
                      onClick={() => {
                        setOpenDocumentModal(true);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="box-content">
                  <SlGraduation className="grad-icon" />
                  <h3 className="title">Document Title</h3>
                  <p className="small-title">
                    a subtext to support the document title
                  </p>
                  <div className="btn-box">
                    <button
                      className="btn"
                      onClick={() => {
                        setOpenDocumentModal(true);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <DashboardModal isOpen={openApprovalModal}>
        <ApprovalModalContent setOpenApprovalModal={setOpenApprovalModal} />
      </DashboardModal>

      <DashboardModal isOpen={openRejectionModal}>
        <RejectionModalContent setOpenRejectionModal={setOpenRejectionModal} />
      </DashboardModal>

      <DashboardModal isOpen={openDocumentModal}>
        <DocumentModalContent
          setOpenDocumentModal={setOpenDocumentModal}
          imgSrc={cv}
        />
      </DashboardModal>
    </>
  );
};

export default SingleApplicantPage;
