import React, { memo, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import DashboardDrawer from "../../components/dashboardDrawer/DashboardDrawer";
import "./Dashboard.css";
import DashboardNavbarMobile from "../../components/dashboardNavbarMobile/DashboardNavbarMobile";
import DashboardHeadIcon from "../../components/dashboardHeadIcon/DashboardHeadIcon";
import { dashboardItems } from "../../components/dashboardDrawer/NavigationData";
import { getLocalStorageWithExpiration } from "../../utils";

const Dashboard = () => {
  const navigate = useNavigate();
  const key = "user-admin-values";
  const access_token = getLocalStorageWithExpiration(key);

  useEffect(() => {
    if (access_token === null) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="dash-desktop">
        <DashboardDrawer navList={dashboardItems} />
      </div>
      <div className="dash-mobile">
        <DashboardNavbarMobile navList={dashboardItems} />
      </div>
      <div className="regular-dashboard">
        <div className="inner">
          <div className="right-icons">
            <DashboardHeadIcon />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default memo(Dashboard);
