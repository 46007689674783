import React from "react";
import "./SingleApplicant.css";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";

export const SingleApplicant = ({ name, email, date, status, id }) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const createdDate = new Date(date);
  const monthIndex = createdDate.getMonth();
  const monthName = monthNames[monthIndex];
  const day = createdDate.getDate();
  const year = createdDate.getFullYear();

  return (
    <div className="content">
      <input type="checkbox" className="check-mobile" />
      <div className="data-content">
        <input type="checkbox" className="check" />
        <div className="part-tr">
          <h5>Full Name</h5>
          <h6>{name}</h6>
        </div>

        <div className="part-tr">
          <h5>Email</h5>
          <h6>{email.length > 15 ? email.substring(0, 15) + "..." : email}</h6>
        </div>
        <div className="part-tr">
          <h5>Date Applied</h5>
          <h6>
            {monthName} {day}, {year}
          </h6>
        </div>
        <div className="part-tr">
          <h5>Status</h5>
          <div className="status-bg">
            <span
              className={`status
            ${status == 1 ? "pend" : status == 2 ? "veri" : null}`}
            >
              {status == 1 ? "Pending" : status === 2 ? "Verified" : null}
            </span>
          </div>
        </div>
        <div className="dot-bg">
          <BiDotsHorizontalRounded />
        </div>
      </div>
      <div className="dot-bg-mobile">
        <BiDotsHorizontalRounded />
      </div>
    </div>
  );
};
