import React, { useState } from "react";
import ApplicantsPage from "../ApplicantsPage";
import "../../applicantMainPage/ApplicantsMainPage.css";
import useQuery from "../../../../hooks/useQuery";
import usePagination from "../../../../hooks/usePagination";
import useApplicantsOrganisation from "../../../../hooks/useApplicantsOrganisation";

const OrganisationApplicant = () => {
  const [status, setStatus] = useState("APPROVED");

  const {
    users,
    usersCount,
    loading,
    getAllOrgUsers,
    getNewOrgUsers,
    getAcceptedOrgUsers,
    getRejectedOrgUsers,
    getBlockedOrgUsers,
  } = useApplicantsOrganisation();

  const { search, handleChange, newData } = useQuery({
    data: users,
  });

  const {
    currentItems,
    handleNextPage,
    handlePrevPage,
    totalPages,
    currentPage,
  } = usePagination({ data: newData });

  return (
    <ApplicantsPage
      userData={currentItems}
      title={"Organisation"}
      loading={loading}
      currentItems={currentItems}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      totalPages={totalPages}
      currentPage={currentPage}
      search={search}
      handleChange={handleChange}
      NoDataMsg={"No User to Display"}
      children={
        <div className="backend-applicants-tabs">
          <span
            onClick={() => {
              setStatus("APPROVED");
              getAllOrgUsers();
            }}
            className={status === "APPROVED" ? "active" : ""}
          >
            All ({usersCount?.allOrg})
          </span>
          <span
            onClick={() => {
              setStatus("PENDING");
              getNewOrgUsers();
            }}
            className={status === "PENDING" ? "active" : ""}
          >
            New ({usersCount?.newOrg})
          </span>
          <span
            onClick={() => {
              setStatus("ACCEPTED");
              getAcceptedOrgUsers();
            }}
            className={status === "ACCEPTED" ? "active" : ""}
          >
            Accepted ({usersCount?.accOrg})
          </span>
          <span
            onClick={() => {
              setStatus("REJECTED");
              getRejectedOrgUsers();
            }}
            className={status === "REJECTED" ? "active" : ""}
          >
            Rejected ({usersCount?.rejOrg})
          </span>
          <span
            onClick={() => {
              setStatus("BLOCKED");
              getBlockedOrgUsers();
            }}
            className={status === "BLOCKED" ? "active" : ""}
          >
            Blocked ({usersCount?.bloOrg})
          </span>
        </div>
      }
    />
  );
};

export default OrganisationApplicant;
