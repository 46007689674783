import { config } from "../../config";
import axios from "axios";

export const getUsersBy = async (token, path_type, acc_type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-${path_type}-${acc_type}`
  );
  return { data: res.data, status: res.status };
};

export const getUsersCountBy = async (token, path_type, acc_type) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-${path_type}-${acc_type}-count`
  );
  return { data: res.data, status: res.status };
};
