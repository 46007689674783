import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom";

const ReturnLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, [navigate]);

  return;
}

export default ReturnLogin