import { config } from "../config";
import axios from "axios";

export const signIn = (fields) =>
  axios.post(`${config.testApiBaseUrl}/login`, fields).then((res) => {
    return { data: res.data, status: res.status };
  });

export const getAdminUsers = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(`${config.testApiBaseUrl}/myAdminUsers`);
  return { data: res.data, status: res.status };
};