import React, { useState } from "react";

const useQuery = ({ data }) => {
  const [search, setSearch] = useState("");
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const filteredData = data?.filter((d) => {
    return (
      search.length > 0 && d.name.toLowerCase().includes(search?.toLowerCase())
    );
  });

  const newData = search.length > 0 ? filteredData : data;
  return { search, handleChange, newData };
};

export default useQuery;
