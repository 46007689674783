import { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import { getUsersCountBy, getUsersBy } from "../endpoints/all";

const key = "user-admin-values";
const useApplicantsOverView = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usersCount, setUsersCount] = useState({
    newAll: 0,
    allIn: 0,
    allOrg: 0,
  });
  const keyValues = getLocalStorageWithExpiration(key);

  const getNewUsersAll = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "locum";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allNewLocum);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get new users", errorProps);
      }
      setLoading(false);
      setUsers([]);
    }
  };

  const getNewUsersAllCount = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "locum";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          newAll: data?.allNewLocumCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get all users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, newAll: 0 }));
    }
  };

  const getAllInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          allIn: data?.allIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get all individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, allIn: 0 }));
    }
  };

  const getAllOrgUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "organisation";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          allOrg: data?.allOrganisationCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get all organisation users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, allOrg: 0 }));
    }
  };

  useEffect(() => {
    if (keyValues) {
      getNewUsersAll();
      getNewUsersAllCount();
      getAllInUsersCount();
      getAllOrgUsersCount();
    }
  }, []);

  return {
    users,
    loading,
    usersCount,
  };
};

export default useApplicantsOverView;
