import React, { useState } from "react";
import ApplicantsPage from "../ApplicantsPage";
import "../../applicantMainPage/ApplicantsMainPage.css";
import usePagination from "../../../../hooks/usePagination";
import useQuery from "../../../../hooks/useQuery";
import useApplicantsIndividual from "../../../../hooks/useApplicantsIndividual";

const IndividualApplicant = () => {
  const [status, setStatus] = useState("APPROVED");

  const {
    users,
    usersCount,
    loading,
    getAllInUsers,
    getNewInUsers,
    getAcceptedInUsers,
    getRejectedInUsers,
    getBlockedInUsers,
  } = useApplicantsIndividual();

  const { search, handleChange, newData } = useQuery({
    data: users,
  });

  const {
    currentItems,
    handleNextPage,
    handlePrevPage,
    totalPages,
    currentPage,
  } = usePagination({ data: newData });

  return (
    <ApplicantsPage
      userData={currentItems}
      title={"Individual"}
      loading={loading}
      currentItems={currentItems}
      handleNextPage={handleNextPage}
      handlePrevPage={handlePrevPage}
      totalPages={totalPages}
      currentPage={currentPage}
      search={search}
      handleChange={handleChange}
      NoDataMsg={"No User to Display"}
      children={
        <div className="backend-applicants-tabs">
          <span
            onClick={() => {
              setStatus("APPROVED");
              getAllInUsers();
            }}
            className={status === "APPROVED" ? "active" : ""}
          >
            All ({usersCount?.allIn})
          </span>
          <span
            onClick={() => {
              setStatus("PENDING");
              getNewInUsers();
            }}
            className={status === "PENDING" ? "active" : ""}
          >
            New ({usersCount?.newIn})
          </span>
          <span
            onClick={() => {
              setStatus("ACCEPTED");
              getAcceptedInUsers();
            }}
            className={status === "ACCEPTED" ? "active" : ""}
          >
            Accepted ({usersCount?.accIn})
          </span>
          <span
            onClick={() => {
              setStatus("REJECTED");
              getRejectedInUsers();
            }}
            className={status === "REJECTED" ? "active" : ""}
          >
            Rejected ({usersCount?.rejIn})
          </span>
          <span
            onClick={() => {
              setStatus("BLOCKED");
              getBlockedInUsers();
            }}
            className={status === "BLOCKED" ? "active" : ""}
          >
            Blocked ({usersCount?.bloIn})
          </span>
        </div>
      }
    />
  );
};

export default IndividualApplicant;
