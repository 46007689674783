import { useEffect, useState } from "react";
import { errorProps, getLocalStorageWithExpiration } from "../utils";
import { getUsersBy, getUsersCountBy } from "../endpoints/all";
import { toast } from "react-toastify";

const key = "user-admin-values";
const useApplicantsIndividual = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [usersCount, setUsersCount] = useState({
    allIn: 0,
    newIn: 0,
    accIn: 0,
    rejIn: 0,
    bloIn: 0,
  });
  const keyValues = getLocalStorageWithExpiration(key);

  const getAllInUsers = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "individual";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allIndividual);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get all individual users", errorProps);
      }
      setLoading(false);
      setUsers([]);
    }
  };

  const getNewInUsers = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "individual";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allNewIndividual);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get new individual users", errorProps);
      }
      setLoading(false);
      setUsers([]);
    }
  };

  const getAcceptedInUsers = async () => {
    const { token } = keyValues;
    const path_type = "accepted";
    const acc_type = "individual";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allAcceptedIndividual);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get accepted individual users", errorProps);
      }
      setLoading(false);
      setUsers([]);
    }
  };

  const getRejectedInUsers = async () => {
    const { token } = keyValues;
    const path_type = "rejected";
    const acc_type = "individual";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allRejectionIndividual);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get rejected individual users", errorProps);
      }
      setLoading(false);
      setUsers([]);
    }
  };

  const getBlockedInUsers = async () => {
    const { token } = keyValues;
    const path_type = "blocked";
    const acc_type = "individual";
    setLoading(true);
    try {
      const { data, status } = await getUsersBy(token, path_type, acc_type);
      if (status === 200 && data?.status) {
        setLoading(false);
        setUsers(data?.allBlockedIndividual);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get blocked individual users", errorProps);
      }
      setLoading(false);
      setUsers((prev) => ({
        ...prev,
        inUsers: [],
      }));
    }
  };

  const getAllInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "all";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          allIn: data?.allIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get all individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, allIn: 0 }));
    }
  };

  const getNewInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "new";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          newIn: data?.allNewIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get new individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, newIn: 0 }));
    }
  };

  const getAcceptedInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "accepted";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          accIn: data?.allAcceptedIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get accepted individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, accIn: 0 }));
    }
  };

  const getRejectedInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "rejected";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          rejIn: data?.allRejectedIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get rejected individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, rejIn: 0 }));
    }
  };

  const getBlockedInUsersCount = async () => {
    const { token } = keyValues;
    const path_type = "blocked";
    const acc_type = "individual";
    try {
      const { data, status } = await getUsersCountBy(
        token,
        path_type,
        acc_type
      );
      if (status === 200 || data?.status) {
        setUsersCount((prev) => ({
          ...prev,
          bloIn: data?.allBlockedIndividualCount,
        }));
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get blocked individual users count", errorProps);
      }
      setUsersCount((prev) => ({ ...prev, bloIn: 0 }));
    }
  };

  useEffect(() => {
    if (keyValues) {
      getAllInUsers();
      getAllInUsersCount();
      getNewInUsersCount();
      getAcceptedInUsersCount();
      getRejectedInUsersCount();
      getBlockedInUsersCount();
    }
  }, []);

  return {
    users,
    loading,
    usersCount,
    getAllInUsers,
    getNewInUsers,
    getAcceptedInUsers,
    getRejectedInUsers,
    getBlockedInUsers,
  };
};

export default useApplicantsIndividual;
