import React, { useState } from "react";
import "./PaymentPage.css";
import { FiSearch } from "react-icons/fi";
import { pendingUsers } from "../../../assets/userData";
import { PiDotsThreeOutline } from "react-icons/pi";
import Button from "@mui/material/Button";
import CustomMenu from "../../../components/customMenu/CustomMenu";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import { LiaTimesSolid } from "react-icons/lia";
import profilepics from "../../../assets/profile-pics-cover.png";
import { BsDot } from "react-icons/bs";
import { PiCopySimpleThin } from "react-icons/pi";

const PaymentPage = () => {
  const [toggle, setToggle] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    { name: "View details", onClick: () => setToggle(true) },
    { name: "Pay now", onClick: () => {} },
  ];

  return (
    <div className="candidates-container">
      <div className="candidates-right">
        <h3>Payment</h3>

        <div className="payment-board">
          <div className="payment-board-header">
            <div className="tab-payment">
              <div className="each-tab-payment active-border">
                <p>Due Payment</p>
              </div>
              <div className="each-tab-payment">
                <p>Successful Payment</p>
              </div>
            </div>

            <div className="search-box">
              <FiSearch color="#bbc2cf" />
              <input type="text" placeholder="Search applicants" />
            </div>
          </div>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Specialty</th>
                  <th>Pending amount</th>
                  <th>Status</th>
                  <th className="last-dot-bg">Action</th>
                </tr>
              </thead>
              <tbody>
                {pendingUsers.map((i, index) => (
                  <tr key={index}>
                    <td>{i.name}</td>
                    <td>{i.speciality}</td>
                    <td>NGN {i.amount}</td>
                    <td>
                      <div className="status">
                        <span
                          className={
                            i.status === "Pending"
                              ? "pend"
                              : i.status === "Successful"
                              ? "succ"
                              : null
                          }
                        >
                          {i.status}
                        </span>
                      </div>
                    </td>
                    <td className="last-dot-bg">
                      <Button onClick={handleClick}>
                        <PiDotsThreeOutline className="menu-icon" />
                      </Button>
                    </td>
                    <CustomMenu
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      options={options}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <CustomDialog open={toggle}>
            <div className="dialog-bg">
              <div className="header-dialog">
                <h6>Details</h6>
                <LiaTimesSolid
                  className="cancel"
                  onClick={() => setToggle(false)}
                />
              </div>
              <div className="section-two-part">
                <div className="profile-part">
                  <img src={profilepics} alt="pics" />
                  <div>
                    <div className="profile-name">
                      <h6>Slye Sylvester</h6>
                      <span>Verified</span>
                    </div>
                    <p>
                      Pharmacist <BsDot /> Slyesylvester@Gmail.com
                      <BsDot />
                      +2347032456721 <BsDot /> Lagos, Nigeria
                    </p>
                  </div>
                </div>

                <div className="first-box">
                  <div className="inner-one">
                    <p>Due amount</p>
                    <h4>NGN 50,000</h4>
                  </div>

                  <div className="inner-one border-right-one">
                    <p>Work status</p>
                    <span>Completed</span>
                  </div>
                </div>

                <div className="second-part">
                  <h5>Work details</h5>
                  <div className="second-box">
                    <div className="item-col border-below">
                      <p>Employer</p>
                      <h6>Medplus Pharmacy</h6>
                    </div>

                    <div className="item-col border-below">
                      <p>Total hours</p>
                      <h6>20hours</h6>
                    </div>

                    <div className="item-col">
                      <p>Duration</p>
                      <h6>2months</h6>
                    </div>
                  </div>
                </div>

                <div className="second-part">
                  <h5>Account details</h5>
                  <div className="second-box">
                    <div className="item-col border-below">
                      <p>Account number</p>
                      <div className="copy-acc">
                        <h6>0339820022</h6>
                        <PiCopySimpleThin />
                      </div>
                    </div>

                    <div className="item-col">
                      <p>Bank</p>
                      <h6>Gtbank</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-bg-proceed">
                <button className="btn-proceed-pay">Proceed to pay</button>
              </div>
            </div>
          </CustomDialog>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
